






















import {Component, Mixins} from 'vue-property-decorator'
import FormMixin from '@/general/mixins/FormMixin'
import AuthenticationService from '@/general/services/AuthenticationService'
import {emailValidator, validator} from '@/modules/users/models/User'
import axios from '@/plugins/axios'

@Component
export default class Login extends Mixins(FormMixin, AuthenticationService) {
  rememberMe: boolean = false
  resetPasswordText = ''
  username: string = ''
  buttonText: string = ''

  validator(): any {
    return { email: emailValidator }
  }

  submit() {
      let that = this
      this.$http({
        method: 'get',
        url: `/api/companies/employees/password/reset/${this.username}`,
      }).finally(() => {
            that.resetPasswordText = "Password reset requested."
          }
      )
    }
}
